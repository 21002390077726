// Bootstrap
@import "~bootstrap/scss/bootstrap";
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/bootstrap-reboot';

@import '~sweetalert2/src/sweetalert2.scss';

@import "../css/plugins/common.css";
@import "../css/plugins/reset.css";

@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';

// Settings
@import "variables";
@import "functions";
@import "settings";

@import "ui";
@import "animations";
@import "main";
@import "home";
