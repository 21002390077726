body {
    &.is_home {
        background-color: white;
    }

    .head {
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 15px;
        padding-top: 15px;
        transition: all 0.5s ease-out;

        img {
            min-width: 120px;
            transition: all 0.5s ease-out;
        }

        .decor {
            display: flex;
            position: relative;
            justify-content: end;
            width: 30%;
            transition: all 0.5s ease-out;

            img {
                min-width: 14px;
                max-width: 14px;
                width: 14px;
                transition: all 0.5s ease-out;
            }

            &:before {
                background-color: black;
                content: "";
                position: absolute;
                height: 1px;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                max-width: 175px;
                width: 85%;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    max-width: inherit;
                    width: 95%;
                }
            }

            &.right {
                justify-content: initial;

                &:before {
                    left: auto;
                    right: 0;
                }

                .wrap-social-media {
                    align-items: center;
                    background-color: white;
                    position: relative;
                    padding: 15px;
                    justify-content: space-between;
                    margin-left: 20%;
                    width: 175px;
                    transition: all 0.5s ease-out;

                    a {
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: 100% auto;
                        transition: all 0.5s ease-out;

                        &.instagram {
                            background-image: url("/images/icon-instagram.svg");
                            height: 45px;
                            width: 40px;
                        }

                        &.facebook {
                            background-image: url("/images/icon-facebook.svg");
                            height: 46px;
                            width: 40px;
                        }

                        &.tiktok {
                            background-image: url("/images/icon-tiktok.svg");
                            height: 41px;
                            width: 30px;
                        }
                    }
                }
            }
        }
    }
}

.HomeBannerBox {
    .item {
        padding: 0;
        position: relative;

        &.style2 {
            .container {
                .BannerCaption {
                    p {
                        margin: 0 auto;
                        width: 266px;

                        @include media-breakpoint-up(md) {
                            margin-left: 29%;
                            margin-top: 15%;
                            width: 450px;
                        }
                        @include media-breakpoint-up(lg) {
                            margin: 0;
                            position: absolute;
                            left: 50%;
                            top: 100%;
                            transform: translate(-50%, 120%);
                        }
                        @include media-breakpoint-up(xl) {
                            transform: translate(-50%, 180%);
                        }
                        @media (min-width: 1800px){
                            transform: translate(-50%, 230%);
                        }

                        span {
                            font-style: italic;
                            position: relative;
                            width: 100%;

                            @include media-breakpoint-up(md) {
                                font-size: 20px;
                                width: 450px;
                            }
                            @include media-breakpoint-up(lg) {
                                font-size: 34px;
                            }

                            &:after {
                                background-color: #d46d74;
                                top: -12px;
                                content: "";
                                height: 1px;
                                left: 0;
                                position: absolute;
                                right: 0;
                                width: 80%;
                                margin: 0 auto;
                            }
                            &:before {
                                background-color: #d46d74;
                                bottom: -12px;
                                content: "";
                                height: 1px;
                                left: 0;
                                position: absolute;
                                right: 0;
                                margin: 0 auto;
                                width: 80%;
                            }

                        }

                        .disclaimer {
                            color: #d46d74;
                            font-size: 11px;
                            display: block;
                            text-align: center;
                            margin-top: 20px;

                            @include media-breakpoint-up(md) {
                                font-size: 18px;
                            }
                            @include media-breakpoint-up(lg) {
                                font-size: 19px;
                            }
                        }
                    }
                }
            }
        }

        .shadow {
            height: 10px;
            position: absolute;
            left: 0;
            top: -10px;
            width: 100%;
        }
        .wrap-video {
            left: 90%;
            top: 30%;
            cursor: pointer;
            position: absolute;
            transform: translate(-90%, -30%);
            -ms-transform: translate(-90%, -30%);
            transition: all 0.5s ease-out;
            width: 140px;

            @include media-breakpoint-up(md) {
                top: 20%;
            }
            @include media-breakpoint-up(lg) {
                left: 70%;
                top: 70%;
                width: 200px;
            }
            @include media-breakpoint-up(xl) {
                width: 275px;
            }

            video {
                border-radius: 20px;
                transition: all 0.5s ease-out;
            }

            &.no-video {
                .play-btn {
                    display: none;
                    cursor: default;
                }
            }

            .play-btn {
                background-color: transparent;
                background-image: url("/images/icon-play.svg");
                background-repeat: no-repeat;
                background-size: 100% auto;
                display: block;
                height: 43px;
                left: 50%;
                top: 100%;
                padding: 0;
                transform: translate(-50%, -162%);
                position: relative;
                width: 43px;

                @include media-breakpoint-up(lg) {
                    height: 68px;
                    width: 68px;
                    transform: translate(-50%, -177%);
                }
            }
        }

        .container {
            position: absolute;
            z-index: 1;
            left: 50%;
            top: 75%;
            padding: 0;
            transform: translate(-50%, -70%);
            line-height: normal;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(sm) {
                top: 42vh;
                left: 0;
                right: 0;
            }
            @include media-breakpoint-up(md) {
                top: 55%;
                left: 50%;
                transform: translate(-50%, -40%);
                -ms-transform: translate(-50%, -40%);
                bottom: unset;
            }

            @include media-breakpoint-up(lg) {
                left: 63%;
                transform: translate(-50%, -50%);
                top: 35%;
                width: 700px;
            }

            .BannerCaption {
                color: white;
                position: relative;
                text-align: center;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    text-align: left;
                }

                > label {
                    color: $text-color-1;
                    display: block;
                    margin-bottom: 10px;
                    text-align: center;
                    transition: all 0.5s ease-out;
                    font-size: 60px;

                    @include media-breakpoint-up(md) {
                        min-height: 90px;
                        margin-bottom: 0;
                        font-size: 90px;
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: 120px;
                    }

                }

                p {
                    text-align: center;
                    transition: all 0.5s ease-out;

                    @include media-breakpoint-up(lg) {
                        margin: 0 auto;
                        width: 565px;
                    }

                    span {
                        font-size: 20px;
                        font-weight: 300;
                        color: $text-color-1;
                        display: block;
                        transition: all 0.5s ease-out;

                        @include media-breakpoint-up(md) {
                            font-size: 34px;
                        }

                        b {
                            font-weight: 700;
                        }
                    }

                }

            }
        }
    }

    .slick-dots {
        bottom: 10%;
        left: 0;
        position: absolute;
        width: 100%;

        @include media-breakpoint-up(lg) {
            left: auto;
            width: 400px;
            right: 0;
        }

        li {

            &.slick-active {
                button {
                    &:before {
                        color: $brand-secondary;
                    }
                }
            }

            button {
                &:before {
                    color: $brand-third;
                    font-size: 15px;
                    opacity: 1;
                }
            }
        }
    }

}

//.HomeBannerBox

.identity {
    background-image: url("/images/nube.webp");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 0 bottom;
    padding-bottom: 70px;
    padding-top: 40px;
    transition: all 0.5s ease-out;

    @include media-breakpoint-up(md) {
        background-image: url("/images/nubes.webp");
    }
    @include media-breakpoint-up(lg) {
        background-position: 0 bottom;
    }
    @include media-breakpoint-up(xl) {
        //background-position: 0 200%;
    }

    img {
        max-width: 620px;
        margin: 0 auto;
        display: block;
        width: 100%;
        transition: all 0.5s ease-out;
    }

    .subtitle {
        color: #F3C5A4;
        font-size: 70px;
        font-family: $fontTitle;
        margin-bottom: 40px;
        margin-top: 40px;
        display: block;
        text-align: center;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            font-size: 105px;
        }
    }

    label {
        color: $text-color-2;
        display: block;
        font-size: 26px;
        text-align: left;
        margin: 0 auto 20px;
        font-weight: 300;
        width: 90%;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            font-size: 34px;
        }
    }

    p {
        font-size: 18px;
        font-weight: 300;
        text-align: left;
        display: block;
        margin: 0 auto;
        width: 90%;
        transition: all 0.5s ease-out;

        b {
            font-weight: 700;
        }
    }
}

//.identity

.our-services {
    background-color: $back-pink;
    background-image: url("/images/extensiones-de-pelo-natural-humano.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center top;
    padding-top: 180px;
    position: relative;
    transition: all 0.5s ease-out;

    @include media-breakpoint-up(md) {
        padding-top: 330px;
    }
    @include media-breakpoint-up(lg) {
        background-size: 906px auto;
        padding-top: 430px;
    }

    .branch-top {
        background-image: url("/images/branch.svg");
        background-size: 100% auto;
        background-position: 0 0;
        background-repeat: no-repeat;
        background-color: $back-pink;
        background-blend-mode: multiply;
        position: absolute;
        top: 20%;
        left: 0;
        height: 246px;
        opacity: 0;
        width: 199px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(lg) {
            opacity: 1;
        }
    }
    .branch-bottom {
        background-image: url("/images/branch.svg");
        background-size: 100% auto;
        background-position: 0 0;
        background-repeat: no-repeat;
        background-color: $back-pink;
        background-blend-mode: multiply;
        position: absolute;
        bottom: 50%;
        right: 0;
        height: 131px;
        width: 106px;
        transform: scaleX(-1) translate(0, -50%);
        z-index: 0;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            height: 246px;
            bottom: 32%;
            width: 199px;
        }
        @include media-breakpoint-up(lg) {
            bottom: 25%;
        }
    }
    .circle-top {
        background-image: url("/images/circle.svg");
        background-size: 100% auto;
        background-position: 0 0;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        right: 0;
        height: 404px;
        width: 283px;
        opacity: 0;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(lg) {
            opacity: 1;
        }
    }
    .circle-bottom {
        background-image: url("/images/circle.svg");
        background-size: 100% auto;
        background-position: 0 0;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 193px;
        width: 163px;
        transform: rotate(180deg);
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            height: 404px;
            width: 340px;
        }
    }

    .title {
        font-size: 64px;
        margin-bottom: 40px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            font-size: 85px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 150px;
        }
    }

    .wrap-lists {
        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        ul {
            padding: 0;
            margin: 0 auto;
            position: relative;
            width: 85%;
            transition: all 0.5s ease-out;

            &:nth-child(2) {
                &:before {
                    @include media-breakpoint-up(lg) {
                        background-color: #DA6E77;
                        content: "";
                        height: 100%;
                        position: absolute;
                        left: -47px;
                        top: 0;
                        width: 1px;
                    }
                    @include media-breakpoint-up(xl) {
                        left: -90px;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                z-index: 1;
                width: 360px;
            }

            li {
                color: $text-color-1;
                font-size: 22px;
                margin-bottom: 20px;
                position: relative;
                margin-left: 20px;
                width: 100%;
                transition: all 0.5s ease-out;

                &:before {
                    content: "";
                    background-color: #DA6E77;
                    display: inline-block;
                    border-radius: 50%;
                    height: 8px;
                    width: 8px;
                    position: absolute;
                    left: -20px;
                    top: 11px;
                }
            }
        }
    }

    .wrap-img {
        margin: 0 auto 60px;
        width: 227px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            width: 320px;
        }
        @include media-breakpoint-up(lg) {
            width: 372px;
        }

        img {
            transition: all 0.5s ease-out;
            width: 100%;
        }
    }

    .rating {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 47px;
    }

    .BtnStd {
        margin: 0 auto 105px;
        width: 267px;
        transition: all 0.5s ease-out;
    }
}

//.our-services

.colors-extensions {
    background-image: url("/images/textura-marmol-xs.webp"), url("/images/textura-marmol-xs.webp");
    background-repeat: no-repeat, no-repeat;
    background-size: 100% auto, 155% auto;
    background-position: center top, center 83%;
    padding-top: 60px;
    position: relative;
    transition: all 0.5s ease-out;

    @include media-breakpoint-up(lg) {
        background-image: url("/images/textura-marmol.webp"), url("/images/textura-marmol-down.webp");
        background-repeat: no-repeat, no-repeat;
        background-size: 100% auto, 100% auto;
        background-position: center top, center bottom;
        padding-top: 95px;
        margin-top: -30px;
    }

    .title {
        color: $text-color-1;
        font-size: 64px;
        margin-bottom: 70px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            font-size: 85px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 150px;
        }
    }

    .wrap-media {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        position: relative;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            margin-bottom: 120px;
            justify-content: center;
        }

        .img-group {
            position: relative;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(md) {
                margin-right: 25px;
            }

            .wrap-before, .wrap-after {
                position: relative;
                transition: all 0.5s ease-out;

                img {
                    min-width: 190px;
                    transition: all 0.5s ease-out;

                    @include media-breakpoint-up(md) {
                        max-width: 185px;
                    }
                }

                label {
                    background-color: #DA6E77;
                    color: white;
                    font-size: 16px;
                    text-align: center;
                    display: block;
                    padding-left: 10px;
                    padding-right: 10px;
                    position: absolute;
                    left: 0;
                    bottom: 15px;
                    transition: all 0.5s ease-out;
                }
            }
        }

        .wrap-video {
            position: relative;
            transition: all 0.5s ease-out;

            video {
                max-width: 285px;
            }

            .play-btn {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    height: 70px;
                    width: 70px;
                }
            }
        }

        .branch {
            background-image: url("/images/branch.svg");
            background-size: 100% auto;
            background-position: 0 0;
            background-repeat: no-repeat;
            position: absolute;
            bottom: -12%;
            right: 13%;
            height: 135px;
            width: 110px;
            transform: translate(0, 0);
            z-index: 0;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(md) {
                bottom: -17%;
                height: 180px;
                right: auto;
                width: 150px;
                z-index: -1;
            }
            @include media-breakpoint-up(lg) {
                bottom: 25%;
            }
        }
    }

    .BtnStd {
        margin: 0 auto;
    }
}
//.colors-extension

.ratings {
    background-image: url("/images/nube-2.webp");
    background-repeat: no-repeat, no-repeat;
    background-size: 200% auto;
    background-position: center top;
    position: relative;

    @include media-breakpoint-up(lg) {
        padding-top: 30px;
        background-size: 100% auto;
    }

    .title {
        color: $text-color-1;
        font-size: 64px;
        margin-bottom: 70px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            font-size: 85px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 150px;
        }
    }

    .rating-carrousel {
        position: relative;

        .item {
            position: relative;
            margin-bottom: 40px;

            img {
                margin: 0 auto;
                max-width: 260px;
                width: 100%;
            }
            label {
                color: #DA6E77;
                font-size: 24px;
                display: block;
                text-align: center;
                margin: 20px auto 20px;
                line-height: 24px;
                width: 90%;
            }
            p {
                font-size: 18px;
                color: $text-color-1;
                text-align: center;
                margin: 0 auto 30px;
                line-height: 18px;
                width: 90%;
            }
            .wrap-stars {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 auto;
                width: 186px;

                img {
                    width: 29px;
                }
            }
        }

        .slick-arrow {
            &.slick-prev {
                left: 0;

                &.slick-disabled {
                    opacity: 0.25;
                }
                &:before {
                    opacity: 1;
                    color: transparent;
                    background-image: url("/images/icon-arrow.svg");
                    background-size: 100% auto;
                    background-repeat: no-repeat;
                    background-position: 0 0;
                    height: 40px;
                    width: 22px;
                    position: absolute;
                }
            }
            &.slick-next {
                right: 25px;

                &.slick-disabled {
                    opacity: 0.25;
                }
                &:before {
                    opacity: 1;
                    color: transparent;
                    background-image: url("/images/icon-arrow.svg");
                    background-size: 100% auto;
                    background-repeat: no-repeat;
                    background-position: 0 0;
                    height: 40px;
                    width: 22px;
                    position: absolute;
                    transform: rotate(180deg);
                }
            }
        }
    }

    .wrap-button {
        margin-bottom: 100px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 130px;
        }

        a {
            margin: 0 auto;
            height: 70px;
            line-height: 18px;
            padding-top: 17px;
            width: 267px;
        }

    }

    .branch {
        background-image: url("/images/branch.svg");
        background-size: 100% auto;
        background-position: 0 0;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0;
        right: 0;
        height: 131px;
        width: 106px;
        transform: scaleX(-1);
        z-index: 0;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            height: 246px;
            bottom: 0;
            width: 199px;
        }
        @include media-breakpoint-up(lg) {
            bottom: 5%;
        }

        &.left {
            left: 0;
            right: auto;
            top: 0;
            transform: scaleX(1);
        }
        &.right {
            top: 0;
        }
    }
}
//.ratings

.in-contact {
    background-color: #FFF9F4;
    background-image: url('/images/contact-textura-xs.webp');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: right bottom;
    padding-bottom: 80px;
    padding-top: 80px;
    position: relative;

    @include media-breakpoint-up(md) {
        background-image: url('/images/textura-contact-left.webp'), url('/images/textura-contact.webp');;
        background-size: auto 100%;
        background-position: left bottom, right bottom;
    }
    @include media-breakpoint-up(xl) {
        padding-bottom: 0;
    }

    .title {
        color: #b78f80;
        font-size: 64px;
        margin: 0 auto;
        width: 300px;

        @include media-breakpoint-up(md) {
            font-size: 85px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 106px;
            width: 100%;
        }
    }

    img {
        &.colorimetria {
            display: block;
            margin: 0 auto;
            max-width: 300px;

            @include media-breakpoint-up(xl) {
                max-width: 532px;
                margin-top: -100px;
                margin-left: 10%;
            }
        }
    }

    form {
        position: relative;
        z-index: 3;

        @include media-breakpoint-up(md) {
            margin: 0 auto;
            width: 670px;
        }

        input {
            background-color: transparent;
            border-radius: 24px;
            border: 1px solid #9B8B76;
            display: block;
            height: 55px;
            margin: 0 auto 40px;
            text-align: center;
            width: 300px;

            &.error {
                border-color: $brand-error;
            }
        }

        textarea {
            background-color: transparent;
            border-radius: 24px;
            border: 1px solid #9B8B76;
            display: block;
            height: 195px;
            margin: 0 auto 40px;
            text-align: left;
            padding: 20px;
            width: 300px;

            @include media-breakpoint-up(md) {
                width: 100%;
            }
        }

        a {
            background-color: #926C50;
            color: white;
            font-family: $fontBase;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 52px;
            display: block;
            margin: 0 auto 40px;
            text-transform: capitalize;
            width: 300px;

            strong {
                font-weight: 700;
            }

            &.line-btn {
                background-color: transparent;
                border: 0;
                color: #9B8B76;
                line-height: 1;
                margin: 0;
                text-transform: lowercase;
                display: inline-block;
                height: auto;
                width: auto;
            }
        }

        .wrap-buttons {

            @include media-breakpoint-up(md) {
                display: flex;
                align-items: center;
                justify-content: space-around;
            }

            button {
                background-color: #926C50;
                color: white;
                font-family: $fontBase;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 52px;
                display: block;
                margin: 0 auto 10px;
                text-transform: inherit;
                width: 250px;

                &.no-ok {
                    background-color: $brand-third;
                }
            }
        }
    }

    .branch {
        background-image: url("/images/branch.svg");
        background-size: 100% auto;
        background-position: 0 0;
        background-repeat: no-repeat;
        position: absolute;
        top: 10%;
        right: 0;
        height: 131px;
        width: 106px;
        transform: scaleX(-1);
        z-index: 0;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            height: 246px;
            bottom: 0;
            width: 199px;
        }
        @include media-breakpoint-up(lg) {
            bottom: 5%;
        }

        &.left {
            left: 0;
            right: auto;
            transform: scaleX(1);

            @include media-breakpoint-up(xl) {
                display: none;
            }
        }

        &.right {
            top: 70%;
        }
    }
}

//.in-contact

.bottom-contact {
    background-color: #F9D5B8;
    padding-bottom: 110px;
    padding-top: 47px;

    .wrap-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 15px;
        padding-top: 15px;
        transition: all 0.5s ease-out;

        img {
            min-width: 120px;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(lg) {
                width: 152px;
            }
        }

        .decor {
            display: flex;
            position: relative;
            justify-content: end;
            width: 30%;
            transition: all 0.5s ease-out;

            img {
                min-width: 14px;
                max-width: 14px;
                width: 14px;
                transition: all 0.5s ease-out;
            }

            &:before {
                background-color: black;
                content: "";
                position: absolute;
                height: 1px;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                max-width: 175px;
                width: 85%;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    max-width: inherit;
                    width: 95%;
                }
            }

            &.right {
                justify-content: initial;

                &:before {
                    left: auto;
                    right: 0;
                }

            }
        }
    }

    .wrap-social-media {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: space-between;
        margin: 40px auto 40px;
        width: 265px;
        transition: all 0.5s ease-out;

        a {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100% auto;
            transition: all 0.5s ease-out;

            &.instagram {
                background-image: url("/images/icon-beige-instagram.svg");
                height: 40px;
                width: 40px;
            }

            &.facebook {
                background-image: url("/images/icon-beige-facebook.svg");
                height: 41px;
                width: 41px;
            }

            &.tiktok {
                background-image: url("/images/icon-beige-tiktok.svg");
                height: 36px;
                width: 31px;
            }
            &.location {
                background-image: url("/images/icon-beige-location.svg");
                height: 42px;
                width: 32px;
            }
        }
    }

    .wrap-communication {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            margin: 0 auto;
            width: 600px;
        }

        .wrap-ss {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 25px;
            width: 250px;

            img {
                height: 45px;
                margin-right: 20px;
                width: 45px;

                &.phone {
                    height: 33px;
                    width: 50px;
                }
            }
            a {
                color: #585757;
                font-size: 27px;
                font-weight: 700;
            }
        }
    }

    .address {
        color: #585757;
        font-size: 18px;
        display: block;
        margin: 0 auto;
        text-align: center;
        position: relative;
        z-index: 1;
        width: 320px;

        @include media-breakpoint-up(lg) {
            font-size: 20px;
            width: 420px;
        }
    }

    .branch {
        background-color: #F9D5B8;
        background-image: url("/images/branch.svg");
        background-blend-mode: multiply;
        background-size: 100% auto;
        background-position: 0 0;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0;
        right: 0;
        height: 131px;
        width: 106px;
        transform: scaleX(-1);
        z-index: 0;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            height: 246px;
            bottom: 0;
            width: 199px;
        }

        &.left {
            left: 0;
            right: auto;
            transform: scaleX(1);

        }

    }

    .privacy-notice {
        color: #585757;
        display: block;
        text-align: center;
        font-size: 14px;
        margin-top: 20px;
        text-decoration: underline;
    }
}

.play-btn {
    background-color: transparent;
    background-image: url("/images/icon-play.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    display: block;
    height: 45px;
    width: 45px;
    position: relative;

    @include media-breakpoint-up(md) {
        height: 70px;
        width: 70px;
    }
}

.swal2-close {
    font-size: 55px;
    color: #f2b485;
    right: 7%;
    position: absolute;

    @include media-breakpoint-up(md) {
        right: 25%;
    }
    @include media-breakpoint-up(xl) {
        right: 30%;
    }
}

.swal2-html-container {
    background-color: transparent;
    height: auto;
    margin: 0 !important;
    width: 100%;
}

.swal2-modal {
    background-color: transparent;
    height: auto;
    padding: 0;
    width: 1200px;
}

.banner-wrap-video {
    background-color: transparent;
    margin: 0 auto;
    width: 85%;

    @include media-breakpoint-up(md) {
        width: 400px;
    }
    @include media-breakpoint-up(xl) {
        width: 450px;
    }
}

.is_privacy_notice {

    .head {
        background-color: transparent;
        background-image: url("/images/back-head.jpg");
        background-repeat: repeat-x;
        background-size: auto 100%;
        padding-bottom: 40px;
    }
    .wrap-content {
        margin: 0 auto 60px;
        width: 85%;

        @include media-breakpoint-up(md) {
            margin-top: 120px;
            padding-bottom: 180px;
        }
        @include media-breakpoint-up(lg) {
            margin-top: 160px;
            width: 892px;
        }

        .subtitle {
            color: #9B8B76;
            font-size: 28px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 30px;
            display: block;
        }

        p {
            color: #9B8B76;
            font-size: 18px;
            font-weight: 300;
        }
        strong {
            font-weight: 700;
        }
        ul {
            li{
                padding-left: 15px;
                list-style: circle;
                margin-bottom: 10px;
            }
        }

        .branch-bottom {
            background-image: url("/images/branch.svg");
            background-size: 100% auto;
            background-position: 0 0;
            background-repeat: no-repeat;
            background-color: white;
            background-blend-mode: multiply;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 246px;
            opacity: 0;
            width: 199px;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(md) {
                opacity: 1;
            }
            @include media-breakpoint-up(lg) {
                bottom: 0;
            }
        }
        .branch-top {
            background-image: url("/images/branch.svg");
            background-size: 100% auto;
            background-position: 0 0;
            background-repeat: no-repeat;
            background-color: white;
            background-blend-mode: multiply;
            position: absolute;
            opacity: 0;
            bottom: 50%;
            right: 0;
            height: 131px;
            width: 106px;
            transform: scaleX(-1) translate(0, -50%);
            z-index: 0;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(md) {
                opacity: 1;
                height: 200px;
                top: 16%;
                width: 159px;
            }
            @include media-breakpoint-up(lg) {
                //bottom: 25%;
            }
        }
    }
}
